<template>
  <div class="home-box">
    <!--  公司介绍视频  -->
    <div class="solution-section">
      <div class="section">
        <div class="section-intro">
          <div class="company">{{ $store.state.language == 0 ? '哈工博视' : 'BOSIGHT' }}</div>
          <div class="title wow fadeInLeft" data-wow-duration="3s" data-wow-delay="0.5s">{{ $store.state.language == 0 ? 'AI赋能皮革数字化' : 'AI empowers leather digitization' }}</div>
          <div class="desc wow fadeInLeft" data-wow-delay="1s">{{ $store.state.language == 0 ? '提供针对皮革行业的各类视觉检测技术与软件服务' : 'Provide various visual inspection technologies and software services for the leather industry.' }}</div>
          <div class="btn-group">
            <div class="btn wow rollIn" data-wow-delay=".3s" @click="openDialog">{{ $store.state.language == 0 ? '联系我们' : 'Contact Us' }}</div>
            <div class="btn wow rollIn" data-wow-delay=".5s" @click="videoDialog(0)">{{ $store.state.language == 0 ? '查看产品视频' : 'Get Product Video' }}</div>
            <div class="btn long wow rollIn  " data-wow-delay=".7s"   @click="openProducts">
              {{ $store.state.language == 0 ? '了解更多产品信息' : 'Get More Product Info' }}
              <img src="../assets/index/left@2x.png" />
            </div>
          </div>
        </div>
        <div class="section-video wow fadeInRight" data-wow-duration="2" data-wow-delay="0.5s">
          <video
              autoplay
              muted
              controls
              controlslist="nodownload"
              :src="$store.state.company_video"
          />
        </div>
      </div>
    </div>
    <!--  汽车产品解决方案  -->
    <div class="solution-car wow fadeInRight" data-wow-delay="0.5s">
      <div class="car">
        <div class="car-video">
          <div class="video">
            <video
                autoplay
                muted
                loop
                controlslist="nodownload"
                ref="videoPlayer"
                id="videoPlayer"
                :src="loopVideo"
            >
            </video>
          </div>
          <div class="full-video wow fadeInLeft"  @click="videoDialog(1)" data-wow-duration="3s" data-wow-iteration="infinite">
            {{ $store.state.language == 0 ? '查看完整视频' : 'Get Video' }}<img src="../assets/index/left@2x.png" /></div>
        </div>
        <div class="car-advantage">
          <div :class="['ca-item wow fadeInRight',$store.state.language == 0 ? '' : 'en']" data-wow-delay="0.5s">
            <div class="ai-icon"><img src="../assets/index/icon1@2x.png" /></div>
            <div class="ai-desc">
              <p :class="['title',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '蓝湿皮自动检测分级设备' : 'AI Wet-Blue Hide Grading Equipment' }}</p>
              <p :class="['desc',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '准确一致的分级' : 'Consistent Grading Equipment for Wet-Blue Leather' }}</p>
            </div>
          </div>
          <div :class="['ca-item wow fadeInRight',$store.state.language == 0 ? '' : 'en']" data-wow-delay="0.5s">
            <div class="ai-icon "><img src="../assets/index/icon2@2x.png" /></div>
            <div class="ai-desc">
              <p :class="['title',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '高精度成品皮缺陷识别': 'Finished Leather Defect Recognition Equipment' }}</p>
              <p :class="['desc',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '100 % 准确识别': '100% Accurate Recognition' }}</p>
            </div>
          </div>
          <div :class="['ca-item wow fadeInRight',$store.state.language == 0 ? '' : 'en']" data-wow-delay="0.5s">
            <div class="ai-icon"><img src="../assets/index/icon3@2x.png" /></div>
            <div class="ai-desc">
              <p :class="['title',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '汽车革智能裁断方案' : 'Intelligent Cutting Solution for Automotive Leather' }}</p>
              <p :class="['desc',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '适用各类裁断终端' : 'Applicable to Various Cutting Terminals' }}</p>
            </div>
          </div>
          <div :class="['ca-item wow fadeInRight',$store.state.language == 0 ? '' : 'en']" data-wow-delay="0.5s">
            <div class="ai-icon"><img src="../assets/index/icon4@2x.png" /></div>
            <div class="ai-desc">
              <p :class="['title',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '高效可定制的排料软件' : 'Efficient and Customizable Nesting Software' }}</p>
              <p :class="['desc',$store.state.language == 0 ? '' : 'en']">{{ $store.state.language == 0 ? '更高的利用率，可定制' : 'Higher Utilization, Customizable' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  真皮解决方案  -->
    <Products />
    <!--  客户反馈  -->
    <Feedback />
    <!--  合作企业  -->
    <div class="solution-cooperatives">
      <div class="cooperatives">
        <p class="title wow fadeInDown ">{{ $store.state.language == 0 ? '企业信赖的选择' : 'Trusted By Leading Tanners Worldwide' }}</p>
        <div class="cooperative-list wow fadeInDown" data-wow-delay=".5s">
          <ul>
            <li v-for="(item, index) in cooperatives" :key="index" >
              <div >
                <img :src="item.logoUrl"  style="border-radius: 4px;"/>
                <p v-if="$store.state.language == 0">{{ item.name.split('&&&')[$store.state.language] || item.name}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--  哈工博视介绍  -->
    <div class="solution-about">
      <div class="about">
        <div class="intro">
          <p class="title wow fadeInLeft">{{ `${$store.state.company_name.split('&&&')[$store.state.language] || $store.state.company_name}` }} <br>{{`${$store.state.language == 0 ? '(简称：' : ''}${$store.state.language == 0 ? $store.state.company_abbreviation+')' : ''}`}}</p>
          <em class="title_en wow fadeInLeft" v-if="$store.state.language == 0">{{ $store.state.company_name_en || 'BOSIGHT'}}</em>
          <p :class="['desc wow fadeInLeft', $store.state.language == 0 ? '' : 'en']">{{ $store.state.company_introduction.split('&&&')[$store.state.language] || $store.state.company_introduction }}</p>
          <div class="btn long wow fadeInLeft"  data-wow-duration="3s" data-wow-iteration="infinite" @click="openProducts">
            {{ $store.state.language == 0 ? '了解更多' : 'Get More' }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img  src="../assets/index/left@2x.png" />
          </div>
        </div>
        <div class="picture">
          <img :src="$store.state.company_picture" />
        </div>
      </div>
    </div>
    <!--  联系我们  -->
    <Contact />
  </div>
</template>
<script>
import Products from '@/components/Products'
import Feedback from "@/components/Feedback";
import Contact from "@/components/Contact";
import {getEnterprises, getVideos  } from "@/api";

export default {
  components:{
    Products,
    Feedback,
    Contact
  },
  data () {
    return {
      cooperatives: [],
      carVideoImg: '',
      loopVideo: '',
    }
  },
  mounted () {
    let wow = new this.$wow.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    });
    wow.init();
    this.getCooperatives();
    this.getCarVideo();
    this.getLoopVideo();
  },
  methods: {
    openDialog () {
      this.$dialog.open();
    },
    getCarVideo () {
      const that = this;
      getVideos("3").then(res => {
        if(res.code == 200) {
          const data = res.data;
          that.carVideoImg = data.imgUrl;
        }
      })
    },
    videoDialog (type) {
      /*
      * 公司简介视频 0
        汽车产品介绍 1
        蓝湿皮现场视频 2
        蓝湿皮详情视频 3
        缺陷识别机详情视频 4
      * */
      const that = this;
      getVideos(type).then(res => {
        if(res.code == 200) {
          const data = res.data;
          that.$video.open(data.name, data.videoUrl);
        }
      })
    },
    openProducts () {
      this.$router.push({ name: 'products' })
    },
    getCooperatives () {
      const that = this;
      getEnterprises().then(res => {
        if (res.code == 200) {
          that.cooperatives = res.data;
        }
      })
    },
    getLoopVideo(){
      getVideos(3).then(res => {
        const data = res.data;
        this.loopVideo = data.videoUrl;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.home-box{
  //width: 100vw;
  display: flex;
  flex-direction: column;
  // 公司介绍视频
  .solution-section{
    //width: 100vw;
    height: 580px;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .section{
      width: 1200px;
      //width: 1440px;
      height: 580px;
      display: flex;
      flex-direction: row;
      .section-intro{
        width: calc(554px*0.83);
        //width: 554px;
        height: 580px;
        margin-right: 75px;
        text-align: left;
        .company{
          margin-bottom: 5px;
          font-weight: 500;
          font-size: 15px;
          color: #2F318B;
        }
        .title{
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 50px;
          color: #222222;
        }
        .desc{
          margin-bottom: 68px;
          font-weight: 400;
          font-size: 20px;
          color: #444444;
        }
        .btn-group {
          display: flex;
          flex-direction: column;
          .btn{
            width: 169px;
            height: 50px;
            margin-bottom: 20px;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #2F318B;

            font-weight: 500;
            font-size: 16px;
            color: #2F318B;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.long{
              width: 224px;
              img{
                width: 36px;
                height: 7px;
                margin-left: 10px;
              }
            }
          }
        }
      }
      .section-video{
        //width: 811px;
        width: calc(811px*0.83);
        height: 477px;
        border-radius: 4px;
        background: #000;
        cursor: pointer;
        img{
          width: 811px;
          height: 477px;
        }
        video{
          //width: 811px;
          width: calc(811px*0.83);
          height: 477px;
          border-radius: 10px;
        }
      }
    }
  }
  // 汽车产品解决方案
  .solution-car{
    //width: 100vw;
    height: 660px;
    //padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .car{
      //width: 1440px;
      width: 1200px;
      height: 660px;
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      .car-video{
        //width: 715px;
        width: calc(715px*0.83);
        height: 660px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        .video{
          //width: 715px;
          width: calc(715px*0.83);
          height: 423px;
          color:#fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          video{
            //width: 715px;
            width: calc(715px*0.83);
            height: 423px;
            border-radius: 10px;
          }
        }
        .full-video{
          width: 261px;
          height: 50px;
          margin-top: 70px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #2F318B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          color: #2F318B;
          cursor: pointer;
          img{
            width: 36px;
            height: 7px;
            margin-left: 10px;
          }
        }
      }
      .car-advantage{
        //width: 555px;
        width: calc(555px*0.83);
        height: 660px;
        margin-left: 145px;
        display: flex;
        flex-direction: column;
        .ca-item{
          display: flex!important;
          flex-direction: row;
          margin-bottom: 75px;
          &.en{
            margin-bottom: 35px!important;
          }
          .ai-icon{
            margin-right: 45px;
            img{
              //width: 70px;
              //height: 70px;
              width: calc(70px*0.83);
              height: calc(70px*0.83);
            }
          }
          .ai-desc{
            p{
              margin: 0;
              padding: 0;
              text-align: left;
            }
            .title{
              margin-bottom: 10px;
              font-weight: 500;
              //font-size: 40px;
              font-size: 32px;
              color: #222222;
              &.en{
                font-weight: 700;
                font-size: 26px!important;
              }
            }
            .desc{
              font-weight: 500;
              font-size: 20px;
              color: #666666;
            }
          }
        }
      }
    }
  }
  // 合作企业
  .solution-cooperatives{
   //width: 100vw;
    height: calc(840px*0.83);
    //height: 840px;
    display: flex;
    justify-content: center;
    align-items: center;
    .cooperatives{
      //width: 1440px;
      width: 1200px;
      height: calc(740px*0.83);
      padding-top: 100px;
      .title{
        font-weight: 500;
        font-size: 40px;
        color: #222222;
        text-align: center;
      }
      .cooperative-list{
        margin-top: 70px;
        ul{
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0!important;
          margin: 0!important;
          li{
            margin-bottom: 100px;
            text-align: center;
            img{
              width: 250px;
              height: 110px;
            }
            p{
              padding-top: 20px;
              font-weight: 500;
              font-size: 30px;
              color: #222222;
            }
          }
        }
      }
    }
  }
  // 哈工博视介绍
  .solution-about{
    width: 100%;
    height: 744px;
    background: url("../assets/index/bg@2x.png") no-repeat;
    background-size: 100% 100%;
    border-bottom: 4px solid #2f3188;
    display: flex;
    justify-content: center;
    align-items: center;
    .about{
      width: 1200px;
      //width: 1440px;
      //height: 626px;
      height:calc(626px*0.83);
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #D2D2D2;
      display: flex;
      flex-direction: row;
      box-shadow: 5px 5px 5px #b5b5b5;
      .intro{
        width: calc(625px*0.83);
        //width: 625px;
        height: 506px;
        padding: 60px;
        .title{
          font-weight: 500;
          font-size: 32px;
          //font-size: 40px;
          color: #222222;
        }
        .title_en{
          font-weight: 400;
          font-size: 16px;
          //font-size: 20px;
          color: #666666;
          font-style: normal;
        }
        .desc{
          margin-top: 32px;
          font-weight: 400;
          font-size: 16px;
          //font-size: 22px;
          color: #444444;
          line-height: 43px;
          text-align: left;
          &.en{
            line-height: 28px!important;
          }
        }
        .btn{
          width: 169px;
          height: 40px;
          //height: 50px;
          margin-top: 30px;
          //margin-top: 70px;
          margin-bottom: 20px;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #2F318B;
          font-weight: 500;
          font-size: 16px;
          color: #2F318B;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.long{
            width: 224px;
            img{
              width: 36px;
              height: 7px;
              margin-left: 10px;
            }
          }
        }
      }
      .picture{
        width: calc(695px*0.83);
        //width: 695px;
        //height: 626px;
        height: calc(626px*0.83);
        img{
          width: calc(695px*0.83);
          //width: 695px;
          height: calc(626px*0.83);
          //height: 626px;
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
}
</style>
