<template>
  <div class="solution-dermis">
    <div class="dermis">
      <p class="title wow fadeInDown">{{ $store.state.language == 0 ? '多元产品赋能真皮行业革新' : 'Diversified Products Drive Leather Industry Innovation' }}</p>
      <div class="swiper">
        <div class="swiper-item">
          <div class="pro-img wow fadeInLeft" @click="handleDetail"><img :src="$store.state.product_list[type].img" /></div>
          <div class="pro-desc wow fadeInLeft " :style="styleObject">
            <p :class="['pro-title wow  fadeInLeft', $store.state.language==0 ? '' : 'en']" @click="handleDetail">{{$store.state.product_list[type].title.split('&&&')[$store.state.language] || $store.state.product_list[type].title}}</p>
            <p :class="['pro-mic', $store.state.language==0 ? '' : 'en']">{{ $store.state.product_list[type].mic }}</p>
            <div class="pro-item wow fadeInLeft" v-for="(item, index) in ($store.state.product_list[type].list.split('&&&')[$store.state.language] || $store.state.product_list[type].list)" :key="index">· {{ item }}</div>
          </div>
          <div class="swiper-btn-group wow fadeInDown">
            <div :class="['sbg-btn', type == 0 ? 'active' : '']" @click="() => handleSwiper(0)">
              <div class="left"></div>
            </div>
            <div :class="['sbg-btn', type == 1 ? 'active' : '']" @click="() => handleSwiper(1)">
              <div class="right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      type: 0,
    }
  },
  mounted() {
    let wow = new this.$wow.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    });
    wow.init();
  },
  computed: {
    styleObject() {
      return {
        backgroundImage: `url(${this.$store.state.product_list[this.type].bg})`
      }
    }
  },
  methods: {
    handleSwiper (type) {
      this.type = type;
    },
    handleDetail () {
      this.$router.push({ name: 'product', params: { id: this.$store.state.product_list[this.type].id }})
    },
  }
}
</script>
<style lang="scss" scoped>
.solution-dermis{
  //width: 100vw;
  height: 640px;
  //height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  .dermis {
    width: 1200px;
    //width: 1440px;
    height: 560px;
    //height: 700px;
    //padding-top: 100px;
    .title {
      margin-bottom: 45px;
      font-weight: 500;
      font-size: 40px;
      color: #222222;
      text-align: center;
    }
    .swiper {
      width: 1200px;
      //width: 1440px;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      .swiper-item{
        width: 1200px;
        //width: 1440px;
        height: 500px;
        position: relative;
        z-index: 0;
        .pro-img{
          width: calc(700px*0.83);
          //width: 700px;
          height: calc(450px*0.83);
          //height: 450px;
          background: #000;
          position: absolute;
          left: 0;
          top:50px;
          z-index: 9;
          border-radius: 6px;
          margin-top: -2px;
          cursor: pointer;
          img{
            //width: 700px;
            width: calc(700px*0.83);
            height: calc(450px*0.83);
            //height: 450px;
            border-radius: 8px;
          }
        }
        .pro-desc{
          width: calc(700px*0.84);
          //width: 700px;
          height: calc(440px*0.84);
          //height: 440px;
          padding-left: calc(115px*0.84);
          //padding-left: 115px;
          padding-top: 60px;
          //background: #0f0;
          position: absolute;
          left: calc(625px*0.83);
          z-index: 8;
         ßbackground: url("../assets/index/product_bg1@2x.png");
          background-size: 100% 100%;
          border-radius: 16px;
          //box-shadow: 5px 0px 15px #b5b5b5;ßß
          .pro-title{
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 32px;
            color: #222;
            &.en{
              margin-bottom: 10px!important;
            }
            &:hover{
              cursor: pointer;
              color: #a3a3a3;
            }
          }
          .pro-mic{
            margin-bottom: 30px;
            font-weight: 400;
            font-size: 12px;
            color: #222;
            &.en{
              margin-bottom: 15px;
            }
          }
          .pro-item{
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 14px;
            color: #222;
          }
        }
        .swiper-btn-group{
          position: absolute;
          left: 620px;
          //left: 750px;
          top:360px;
          //top:390px;
          z-index: 10;
          display: flex;
          flex-direction: row;
          .sbg-btn{
            width: 45px;
            height: 45px;
            border-radius: 45px;
            border: 1px solid #2F318B;
            margin-right: 5px;
            cursor: pointer;
            .left{
              width: 0;
              height: 0;
              margin: 17px 16.5px 16.5px 14px;
              border-top: 5px solid transparent;
              border-right: 5px solid #2F318B;
              border-left: 5px solid transparent;
              border-bottom: 5px solid transparent;
            }
            .right{
              width: 0;
              height: 0;
              margin: 17px 16.5px 16.5px 20px;
              border-top: 5px solid transparent;
              border-right: 5px solid transparent;
              border-left: 5px solid #2F318B;
              border-bottom: 5px solid transparent;
            }
            &.active{
              background: #2F318B;
              .left{
                border-right: 5px solid #fff;
              }
              .right{
                border-left: 5px solid #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
